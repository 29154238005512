import React from 'react';
// import { useNavigate } from 'react-router-dom';

import {
	Box, 
	Container, 
	Grid, 
	IconButton, 
	Link, 
	Typography 
} from '@mui/material';

import Logo from "../../assets/icons/logo-white.svg";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import FedNowIcon from "../../assets/icons/fed-now.png";
import AchIcon from "../../assets/icons/ach.png";
import RtpIcon from "../../assets/icons/rtp.png";

import "./styles.scss";

const Footer = () => {
	// const navigate = useNavigate();

	// const handleNavigate = (route) => {
	// 	handleGoTop();
	// 	navigate(route);
	// }

	const handleGoTop = () => {
		document.body.scrollTop = 0; // For Safari
  	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

  return (
    <Box className="footer">
      <Container maxWidth="xl">
				<Box>
					<Box sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
						<img
							src={Logo}
							alt='lusid-footer-logo'
							style={{ width: "250px" }}
							/>
					</Box>

					<Grid container 
						spacing={2}
						rowSpacing={6}
						className="flexFSSBRow" 
						sx={{ paddingTop: "40px", paddingBottom: "50px" }}>	
						<Grid item xs={12} sm={6} md={"auto"} lg={"auto"}>
							<Typography variant='h6' className='footer__heading'>Products</Typography>
							<Link
								href="https://lusid.ai/products/accounts-payable"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Accounts payable</Typography>
							</Link>
							<Link
								href="https://lusid.ai/products/accounts-receivable"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Accounts receivable</Typography>
							</Link>
						</Grid>
						{/* <Box>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/capabilities")}>Capabilities</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#instant-payment")}>Instant Payment</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#issue-virtual-card")}>Issue Virtual Card</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#tap-to-pay")}>Tap to Pay</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#scan-to-pay")}>Scan to Pay</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#control")}>Controls & Compliance</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#easy-bill-capture")}>Easy Bill Capture</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#pay-circle")}>Pay Circle</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#automation")}>Spend Automation</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#vendor")}>Payee/Vendor Management</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#bio")}>Biometrics Payment</Typography>
						</Box> */}
						{/* <Box>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/#solutions")}>Solutions</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Solutions Overview</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Never miss a bill</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Control & reconcile spend</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Policy Compliance</Typography>
						</Box> */}
						{/* <Box>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/#developers")}>Developers</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#developers")}>Developer Portal</Typography>
						</Box> */}
           	<Grid item xs={12} sm={6} md={"auto"} lg={"auto"}>
              <Typography variant='h6' className='footer__heading'>Company</Typography>
							<Link
								href="https://lusid.ai/about-us"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>About us</Typography>
							</Link>
							<Link
								href="https://lusid.ai/contact-us"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Contact us</Typography>
							</Link>
						</Grid>
						<Grid item xs={12} sm={6} md={"auto"} lg={"auto"}>
							<Typography variant='h6' className='footer__heading'>Legal</Typography>
							<Link
								href="https://lusid.ai/terms-of-service"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Terms of service</Typography>
							</Link>
							<Link
								href="https://www.currencycloud.com/wp-content/uploads/2024/08/Currencycloud-Terms-UK-TCCL-15-August-24.pdf"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>CC terms</Typography>
							</Link>
							<Link
								href="https://lusid.ai/security"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Security</Typography>
							</Link>
							<Link
								href="https://lusid.ai/privacy-policy"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Privacy</Typography>
							</Link>		
						</Grid>
					</Grid>

					<Box className="flexCenterSBRow"
						sx={{
							flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
						}}>
						<Box>
							<Typography
								color={"white"}
								textAlign={"end"}
								variant='caption'>
								© Copyright 2025 Lusid Inc.
							</Typography><br/>
							<Typography
								color={"lightgrey"}
								textAlign={"end"}
								variant='caption'>
								Lusid.ai and LusidPay.com are part of Lusid Inc.<br/>
							</Typography>
						</Box>

						<Box
							className="flexCenterCenterColumn"
							gap={"10px"}
							sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
							<Box>
								<Typography
									textAlign={"center"}
									color={"lightgrey"}
									variant='caption'>
									Proudly working with the following technology networks:
								</Typography>
							</Box>
							<Box 
								className="flexCenter_Row"
								gap={"20px"}
								sx={{
									flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
								}}
								>
								<img	
									src={FedNowIcon}
									alt='fed-now'
									style={{
										height: "40px",
									}}
									/>
								<img	
									src={RtpIcon}
									alt='rtp'
									style={{
										height: "50px"
									}}
									/>
								<img	
									src={AchIcon}
									alt='ach'
									style={{
										height: "50px"
									}}
									/>
							</Box>
						
						</Box>
					</Box>


					<Box
						className="flexCenterCenterColumn"
						gap={"10px"}
						sx={{ paddingTop: "20px", paddingBottom: "40px" }}>
						<Typography
							textAlign={"center"}
							color={"lightgrey"}
							variant='caption'>
							Lusid is a financial technology company. Payment services for Lusid Inc. are provided by The Currency Cloud Limited. Registered in England No. 06323311. Registered Office: Stewardship Building 1st Floor, 12 Steward Street London E1 6FQ. The Currency Cloud Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 for the issuing of electronic money (FRN: 900199).
							<br/><br/>
							International payment services in the United States are provided by Visa Global Services Inc. (VGSI), a licensed money transmitter (NMLS ID 181032) in the states listed&nbsp;
							<Link href='https://usa.visa.com/legal/visa-global-services-licenses.html'
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "underline", fontSize: "14px" }}>
								here
							</Link>. VGSI is licensed as a money transmitter by the New York Department of Financial Services. Mailing address: 900 Metro Center Blvd, Mailstop 1Z, Foster City, CA 94404. VGSI is also a registered Money Services Business (“MSB”) with FinCEN and a registered Foreign MSB with FINTRAC. For live customer support contact VGSI at (888) 733-0041.
						</Typography>
					</Box>


					<Box className="flexCenterSBRow">
						<IconButton onClick={handleGoTop}>
							<ArrowUpwardIcon fontSize='large' sx={{ color: "white"}} />
						</IconButton>

						<Box  sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
							
						</Box>
						{/* <Box sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
							<IconButton>
								<LinkedInIcon fontSize='large' sx={{ color: "white"}}/>
							</IconButton>
							<IconButton>
								<XIcon fontSize='large' sx={{ color: "white"}}/>
							</IconButton>
						</Box> */}
					</Box>
				</Box>
			</Container>
    </Box>
  )
}

export default Footer;